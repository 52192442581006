// Translated
// Migrated
<template lang="pug">
AuthGuard.favourites-container.main-content.mt-5
  .row(v-if="loading")
    .col-12
      HorizontalCardWithImageSkeleton(v-for="i in 3", :key="`skeleton-${i}`")
  .row(v-else)
    NotFound(v-if="bookings.length === 0")
      span(data-i18n="membersNoBookingsYet") {{ $t('membersNoBookingsYet') }}
      span.d-block {{ $user?.email }}
    .col-12(v-for="booking in bookings", :key="`booking-${booking.url}`")
      MyBookingsTripCard(:trip="booking.tripType", :booking="booking")
</template>

<script setup>
// import { faMagnifyingGlass, faTimes } from '@fortawesome/pro-duotone-svg-icons'


const localeStore = useLocaleStore()
const userStore = useUserStore()

// const icons = {
//   magGlass: faMagnifyingGlass,
//   xmark: faTimes
// }


const {
  user: $user,
  getBookings: bookings,
  loading
} = storeToRefs(userStore)

useAsyncData(() => {
  localeStore.SET_PAGE_WIDE_TRANSLATIONS(createPageWideTranslation(['membersMyBookingsTitle']))
})
</script>
