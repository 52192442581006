<template lang="pug">
.pt-5
  ClientOnly(v-if="state === authPaths.login")
    LazyLoginForm
  ClientOnly(v-else-if="state === authPaths.signup")
    LazySignupForm
  .pt-0.pb-5.mb-5(v-else-if="state === authPaths.whatsThis")
    p(data-i18n="memberWhatsThis") {{ $t('memberWhatsThis') }}

    nuxt-link.d-block.mt-3.font-weight-medium(:to="authLinkTo(paths.login)", data-i18n="memberLogIn") {{ $t('memberLogIn') }}
    nuxt-link.d-block.mt-3.font-weight-medium(:to="authLinkTo(paths.signup)", data-i18n="memberSignup") {{ $t('memberSignup') }}

  ClientOnly(v-else-if="state === authPaths.forgot")
    LazyForgotPassword

  div(v-else)
    LazyAuthFeedback
    div.auth-loading
      .spinner-grow.text-primary
</template>

<script>
import { mapState } from 'pinia'
import { faEnvelopeCircleCheck, faSadTear } from '@fortawesome/pro-regular-svg-icons'
import cognitoMixin from '@layers/web/mixins/cognito.mixin'
import contexts from '@layers/web/constants/contexts'
import events from '@layers/web/constants/events.js'
import localStorageKeys from '@layers/web/constants/local-storage-keys'
import { AuthPages } from '@layers/web/utils/auth/auth'

export default defineNuxtComponent({
  head ({ $pinia }) {
    const rootStore = useRootStore($pinia)

    return {
      title: rootStore.pageBannerTitle
    }
  },

  mixins: [cognitoMixin],

  emits: [events.loginModalToggle],

  data () {
    return {
      user: null,
      state: 'loading',
      resetPassword: false,
      timeout: null,
      icons: {
        generic: faSadTear,
        verification: faEnvelopeCircleCheck
      },

      alertTypes: {
        generic: contexts.error,
        verification: contexts.info
      },

      error: null,
      paths: AuthPages
    }
  },

  computed: {
    ...mapState(useLocaleStore, ['canLocaleLogin']),

    ...mapState(useLocaleStore, ['locale', 'localeURLs']),

    ...mapState(useUserStore, ['feedback', 'isLoggedIn'])
  },

  watch: {
    '$router.currentRoute.params' () {
      this.evaluatePathChange()
    },

    '$route.query' (v) {
      if (v.state === 'registrera') {
        this.state = this.authPaths.signup
      } else if (v.state === 'glomt-losenord') {
        this.state = this.authPaths.forgot
      } else if (v.state === 'vad-ar-det-har') {
        this.state = this.authPaths.whatsThis
      } else if (!this.resetPassword) {
        this.state = this.authPaths.login
      }
    }
  },

  created () {
    if (!this.canLocaleLogin) {
      this.$router.push('/')
    }
    this.evaluatePathChange()
  },

  beforeMount () {
    if(this.$route.query.aterstall) {
      this.resetPassword=true
      this.state = this.authPaths.forgot
    }
  },

  beforeUnmount () {
    clearTimeout(this.timeout)
  },

  methods: {
    evaluatePathChange () {
      if (import.meta.browser) {
        this.$event.$emit(events.loginModalToggle, false)
      }

      this.state = this.$router.currentRoute.value?.params?.slug || this.$router.currentRoute.value?.path
        .replace(this.localeURLs.auth, '')
        .replace('/', '')

      const rootStore = useRootStore()

      switch (this.state) {
        case this.authPaths.logout: {
          this.logout()
          break
        }
        case this.authPaths.login: {
          rootStore.SET_PAGE_BANNER_TITLE(this.$t('memberLogIn'))
          const currentRoute = this.$router.currentRoute.value.fullPath
          if (import.meta.browser) {
            window.localStorage.setItem(localStorageKeys.auth.currentRoute, currentRoute)
          }
          break
        }
        case this.authPaths.signup: {
          this._clearState()
          rootStore.SET_PAGE_BANNER_TITLE(this.$t('memberSignup'))
          break
        }
        case this.authPaths.forgot: {
          rootStore.SET_PAGE_BANNER_TITLE(this.$t('memberForgot'))
          break
        }
        case this.authPaths.whatsThis: {
          rootStore.SET_PAGE_BANNER_TITLE(this.$t('memberWhatsThisTitle'))
          break
        }
        default: {
          this.state = 'loading'
          if (this.isLoggedIn) {
            this.didLogIn()
          } else {
            const redirectFunc = () => this.$router.push(this.authPaths.login).catch(() => {})

            if (import.meta.browser) {
              this.timeout = window.setTimeout(redirectFunc, 5000)
            } else {
              redirectFunc()
            }
          }
          break
        }
      }
    },

    logout () {
      this.cognitoHandleLogout()
        .finally(this.cognitoHandleSuccessfulLogout)
    }
  },
})
</script>

