<template lang="pug">
NuxtLayout(name="banner-members")
  div(v-if="currentTabComponent")
    component(
      :is="currentTabComponent"
    )
</template>

<script setup>
import MemberLoginTab from '@/components/auth/tabs/MemberLoginTab.vue';
import MemberFavouritesTab from '@/components/auth/tabs/MemberFavouritesTab.vue';
import MemberMyBookingsTab from '@/components/auth/tabs/MemberMyBookingsTab.vue';
import MemberSettingsTab from '@/components/auth/tabs/MemberSettingsTab.vue';

const userStore = useUserStore()
const activeTab = computed(() => userStore.memberPageTab)
const memberBanner = 'shutterstock_2399743963_s3xpaq'
const { t } = useI18n()

useAsyncData(() => {
    const { $pinia } = useNuxtApp()

    const rootStore = useRootStore($pinia)
    rootStore.SET_PAGE_BANNER({
      title: userStore.isLoggedIn ? t('memberBannerTitle') : '',
      subtitle: userStore.isLoggedIn ? t('memberBannerSubtitle') : '',
      background: {
        image: {
          url: memberBanner
        }
      },
      type: 'small'
    })
    return {}
  })

const currentTabComponent = computed(() => {
  switch (activeTab.value) {
    case 'logga-in':
    case 'login':
      return MemberLoginTab
    case 'favourites':
      return MemberFavouritesTab
    case 'my-bookings':
      return MemberMyBookingsTab
    case 'settings':
      return MemberSettingsTab
    default:
      return null
  }
})
</script>